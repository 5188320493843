.polynomial-report-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.polynomial-report-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.polynomial-report-crud-container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.polynomial-crud-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

