.not-found-page-container {
  background: rgb(255, 232, 142);
  background: radial-gradient(circle, rgba(255, 232, 142, 1) 0%, rgba(249, 187, 1, 1) 100%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-not-found-img {
  width: 50%;
}
