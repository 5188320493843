.generic-modal-form-header {
  display: flex;
  justify-content: space-between;
}

.generic-modal-form-header > .close {
  border: 0;
  background-color: transparent;
  display: flex;
  padding: 0;
}

.generic-modal-form-header > .close > span {
  font-size: 25px;
}
