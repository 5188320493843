.file-reader-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.file-reader-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.file-reader-input {
  max-width: 100% !important;
  width: 100%;
}

.file-reader-file-name {
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.file-reader-file-name > span {
  text-transform: none;
  font-weight: 800;
}

.file-reader-buttons-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
