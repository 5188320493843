.welcome-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.welcome-button {
  width: 25%;
  min-height: 60%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  background-color: transparent;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.welcome-button > p {
  flex-grow: 0;
  font-family: Roboto Slab;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
}
