.freight-time-master-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.freight-time-master-crud-container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.freight-time-master-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
