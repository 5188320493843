.contractor-tariff-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
  padding: 10px;
}

.contractor-tariff-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contractor-tariff-crud-container {
  border-radius: 5px;
  border: 2px solid var(--gray-5, #e0e0e0);
  background: var(--blanco, #fff);
  box-shadow: 4px 5px 7px 0px #d5d6d7;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contractor-tariff-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
