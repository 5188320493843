.extra-carriage-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.extra-carriage-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.extra-carriage-crud-container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.extra-carriage-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
