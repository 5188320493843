.global-zone-map-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
  position: relative;
}

.global-zone-map-page-top-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.global-zone-map-page-total-general-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.global-zone-map-page-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.global-zone-map-page-title-container > h2 {
  margin: 0;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.global-zone-map-buttons-body {
  width: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 50px;
}

.global-zone-map-buttons-body > .img-country-map {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.global-zone-map-buttons-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.global-zone-map-button {
  width: 300px;
  height: 50px;
  background-color: #64a70b !important;
  color: #fff !important;
}

.global-zone-map-button-disabled {
  color: #597a27 !important;
  cursor: not-allowed !important;
}

.global-zone-map-button:hover {
  background-color: #56900a !important;
}

.level-question-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.level-question-button {
  width: 100px;
  height: 40px;
  background-color: #64a70b !important;
  color: #fff !important;
  border: 0 !important;
  border-radius: 5px !important;
  text-transform: uppercase !important;
  font-family: Roboto !important;
}
.level-question-button:hover {
  background-color: #56900a !important;
}
