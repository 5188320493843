.carriage-resume-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.provision-carriage-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
  gap: 10px;
}

.provision-carriage-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.provision-carriage-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.provision-carriage-crud-container {
  width: 100%;
  border-radius: 5px;
  border: 2px solid var(--gray-5, #e0e0e0);
  background: var(--blanco, #fff);
  box-shadow: 4px 5px 7px 0px #d5d6d7;
}

.provision-carriage-crud {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.provision-carriage-crud-top-table {
  padding: 10px 15px;
}

.provision-carriage-crud-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px;
}
