.container-Sidebar {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.Sidebar-top-nav {
  display: flex;
  width: 100%;
  min-height: 62px;
  padding: 10px 100px;
  align-items: center;
  gap: 87px;
  border: 1px solid var(--gray-5, #e0e0e0);
  user-select: none;
  position: relative;
}

.avatar {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.Sidebar-bottom-container {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.left-sidebar {
  min-width: 290px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
  background: var(--white-100, #fff);
  flex-shrink: 1;
  user-select: none;
  position: relative;
}

.sidebar-fade {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease, display 0.3s ease;
}

.right-render {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.sidebar-image {
  display: flex;
  width: 111.176px;
  height: 41.81px;
  flex-shrink: 0;
}

.sidebar-image img {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
}

.title-sidebar {
  width: 825px;
  flex-shrink: 1;
}

.text-title-sidebar {
  color: var(--gray-2, #4f4f4f);
  font-family: Roboto Slab;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.text-title-sidebar strong {
  color: var(--gray-2, #4f4f4f);
  font-family: Roboto Slab;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.top {
  display: flex;
  width: 220px;
  height: 32px;
  padding: 4px 8px 4px 4px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  margin: 20px;
}

.top-welcome {
  width: 90%;
  display: flex;
  align-items: center;
}
.top-welcome-user {
  color: var(--black-100, #1c1c1c);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.middle-container {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-middle {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 20px;
  scroll-margin: 1px;
}
.top-middle > div {
  width: 100%;
}

.top-middle > div ul li ul li {
  margin-left: 10px;
}

/*.top-middle::-webkit-scrollbar {
  display: none;
}*/

.bottom {
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
}

.title-botton-gestion {
  color: var(--gray-4, #bdbdbd);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bottom-logout-middle {
  display: flex;
  height: 28px;
  width: 90%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  flex-shrink: 1;
}
