.center-zone-map-page-container {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
}

.center-zone-map-page-top-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.center-zone-map-page-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.center-zone-map-page-title-container > h2 {
  margin: 0;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.center-zone-map-buttons-section {
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 20px;
}

#center-zone-map {
  width: 100%;
  height: 100%;
}

.zone-question-modal {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
  padding: 25px 40px;
  background-color: #fafafa;
  overflow-y: auto;
}

.zone-question-modal-close-button-container {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.zone-question-modal-x-button {
  border: none;
  background-color: transparent;

  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.zone-question-modal-close-button {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 8px;
  background-color: #64a70b;

  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.zone-question-modal-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #002e2c;
  text-transform: uppercase;
  margin: 0;
}

.zone-question-modal > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.zone-question-modal > div > p {
  cursor: pointer;
  text-decoration: underline;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #002e2c;
  margin: 0;
}

.zone-question-modal > div > p:hover {
  font-weight: 900;
}
