.ifac-page-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: baseline;
}

.ifac-page-body {
  width: 100%;
  height: 100%;
}

.ifac-page-massive-load-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
