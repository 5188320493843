.reporte-volumen-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.reporte-volumen-title {
  font-family: Roboto Slab;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4f4f4f;
  margin: 0 0 0 10px;
}

.reporte-volumen-collapse-filters-container {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reporte-volumen-filters-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reporte-volumen-filters-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.reporte-volumen-filters-subtitle {
  margin: 0;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reporte-volumen-filters-container > div {
  display: flex;
  gap: 10px;
}

.reporte-volumen-export-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px;
}

.reporte-volumen-pie-chart-page-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reporte-volumen-pie-chart-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.reporte-volumen-pie-chart-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.reporte-volumen-pie-chart-wrapper {
  width: 100%;
  height: auto;
}

.reporte-volumen-chart-title {
  text-align: center;
  display: none;
}
