.freight-extra-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.freight-extra-crud-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.freight-extra-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.freight-extra-filters {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
