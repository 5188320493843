.operations-container {
  padding: 10px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.operations-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}

.operations-title {
  margin: 0;
  font-family: Roboto Slab;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4f4f4f;
}

.operations-info {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.operations-info > p {
  margin: 0;
}

.operations-info > p > span {
  padding: 5px;
  background-color: #c9c9c9;
  border-radius: 5px;
  font-family: Roboto;
  text-transform: uppercase;
}

.operations-info > button {
  background-color: #64a70b;
  color: #fff;
  height: 30px;
  width: 150px;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
}

.operations-info > button:hover {
  background-color: #56900a;
}

.operations-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.operations-article {
  padding: 10px 30px 40px 30px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #828282;
}

.operations-article:last-child {
  border-bottom: none;
}

.operations-section-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1;
  max-width: 50%;
}

.operations-section-title-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.operations-section-title-container > h3 {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
}

.operations-section-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.operations-section-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 4px 12px 4px 8px;
}

.operations-section-button:hover {
  border-radius: 8px;
  background-color: #efefef;
}

.operations-section-button:hover > p {
  font-weight: 900;
}

.operations-subtitle-container {
  display: flex;
  padding: 0 30px;
}
.operations-subtitle {
  font-family: Roboto Slab;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4f4f4f;
  margin: 0;
}
