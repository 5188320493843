.foreign-exchange-page {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.foreign-exchange-title {
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.foreign-exchange-estimated-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.foreign-exchange-estimated-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.foreign-exchange-estimated-crud-container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.foreign-exchange-estimated-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
