.gloss-maintainer-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
  gap: 10px;
}

.gloss-maintainer-crud-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gloss-maintainer-crud-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gloss-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
