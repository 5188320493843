.contingency-withholding-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  align-items: baseline;
}

.contingency-withholding-filters {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contingency-withholding-crud {
  width: 100%;
  height: 100%;
}
