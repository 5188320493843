.shipment-carriage-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
}

.shipment-carriage-body {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.shipment-carriage-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.shipment-carriage-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shipment-carriage-filter-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.shipment-carriage-massive-load-filters-modal-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shipment-carriage-massive-load-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shipment-carriage-crud-container {
  width: 100%;
  border-radius: 5px;
  border: 2px solid var(--gray-5, #e0e0e0);
  background: var(--blanco, #fff);
  box-shadow: 4px 5px 7px 0px #d5d6d7;
}

.shipment-carriage-crud {
  width: 100%;
}

.shipment-carriage-crud-top-table {
  padding: 10px 15px;
}
