.ifac-simulated-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  padding: 10px;
  gap: 10px;
}

.ifac-simulated-crud-container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ifac-simulated-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
