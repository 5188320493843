.card-alert-container {
  min-width: 100px;
  height: 86px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
}

.card-alert-title {
  font-family: Roboto;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
}

.card-alert-content-container {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
  font: Roboto !important;
  font-weight: 300;
  align-items: center;
  padding: 0px 5px;
}

.card-alert-content {
  font-feature-settings: cv11 on, cv01 on, ss01 on;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 36px;
}
