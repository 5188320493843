.freigh-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
}

.freigh-page-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.freigh-page-title {
  flex-grow: 0;
  font-family: Roboto Slab;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4f4f4f;
  margin: 0;
}

.freigh-page-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.freigh-page-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.freigh-page-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 80px;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.freigh-page-button:hover p {
  font-weight: 900;
}

.freigh-page-button > p {
  margin: 0;
  text-align: center;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4f4f4f;
  text-decoration: underline;
}
